a {
  color: inherit;
  text-decoration: none;
}

.consultaion-div {
  position: fixed;
  bottom: 11%;
  right: 0;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  grid-gap: 11px;
  background: #fff;
  border-radius: 100px 0 0 100px;
  padding-left: 20px;
  border: 2px solid #701546;
  color: #7e0c47;
  font-weight: bold;
  z-index: 999;
}

.consultation-img {
  position: absolute;
  top: -14px;
  right: -2px;
}

/* 
.page-loader {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100vh;
  transition: 5s ease-in-out;
} */
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#preloader {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: fadeout 7s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 7s;
  /* Firefox < 16 */
  -ms-animation: fadeout 7s;
  /* Internet Explorer */
  -o-animation: fadeout 7s;
  /* Opera < 12.1 */
  animation: fadeout 7s;
}

.preactive {
  animation: fadeout 7s linear;
}

.inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 54px;
  height: 54px;
  margin: auto
}

.page-loader {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fefefe;
  z-index: 100000
}

#preloader span.loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 10px;
  text-indent: -12345px;
  border-top: 1px solid rgba(0, 0, 0, .08);
  border-right: 1px solid rgba(0, 0, 0, .08);
  border-bottom: 1px solid rgba(0, 0, 0, .08);
  border-left: 1px solid rgba(0, 0, 0, .5);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spinner .7s infinite linear;
  -moz-animation: spinner .7s infinite linear;
  -ms-animation: spinner .7s infinite linear;
  -o-animation: spinner .7s infinite linear;
  animation: spinner .7s infinite linear;
  z-index: 100001;

}

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* @-webkit-keyframes fade{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@media only screen and (max-width: 1200px) {
  .consultation-content {
    display: none;
  }

  .consultaion-div {
    bottom: 22%;
    border: 0;
  }

  .desktop {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .consultation-content {
    display: none;
  }

  .consultation-img>img {
    width: 48px;
  }

  .consultaion-div {
    bottom: 26%;
    border: 0;
  }

  .desktop {
    display: none;
  }
}

.slick-slide>div {
  padding: 0 5px;
}

@media only screen and (min-width: 850px) {
  .consultaion-div {
    padding: 10px 70px 10px 15px;
  }
}