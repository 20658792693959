.scroll-to-top-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
    opacity: 1;
}

.scroll-to-top-icon {
    color: white;
    font-size: 20px;
}

@media only screen and (min-width: 1024px) {
    .scroll-to-top-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }
}