.organs-base {
  display: grid;
  grid-template-columns: 20% 45% 30%;
  margin: 50px auto 20px;
  grid-gap: 30px;
}

.human-image {
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background-color: #5b7991;
  padding: 30px;
}

.organ-section {
  margin-top: 20px;
}

.article-list {
  overflow-y: auto;
  height: 450px;
  border-radius: 10px;
}

.article-list::-webkit-scrollbar {
  width: 10px;
}

.article-list::-webkit-scrollbar-track {
  background-color: #fbeaf0;
  border-radius: 10px;
}

.article-list::-webkit-scrollbar-thumb {
  background-color: #d5296c;
  border-radius: 10px;
}

.organ {
  overflow: hidden;
  padding: 10px;
}

.organ:focus-visible {
  border: 0;
  outline: none;
}

.organ img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.organ img:hover {
  transform: scale(1.2);
}

.organs-list {
  position: relative;
  border-radius: 18px;
  background: #f3e6ed;
  padding: 10px 0 0;
}

.custom-prev-arrow {
  position: absolute;
  top: 50%;
  left: -20px;
  z-index: 99;
  transform: translateY(-50%);
}

.custom-next-arrow {
  position: absolute;
  top: 50%;
  right: -20px;
  z-index: 99;
  transform: translateY(-50%);
}

.organs-list img {
  cursor: pointer;
}

.human-body-section {
  margin: auto;
}

/* .article-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 158px 100px 100px;
  grid-gap: 40px;
  margin: 92px;
  align-items: flex-start;
} */

.organs-section {
  border-right: #f6d3e1 2px solid;
  padding-right: 40px;
}

.recent-list {
  display: grid;
  grid-gap: 20px;
}

.article-list>a {
  color: inherit;
  text-decoration: none;
}

.card-wrapper {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.card-image-wrapper>img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

.organ-section>h1 {
  margin: 0;
  margin-left: 25px;
}

.card {
  position: relative;
  width: 32rem;
  height: 12rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
  padding: 1rem 2rem;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.img {
  width: 10rem;
  height: 100%;
  border-radius: 50%;
}

span h1 {
  position: absolute;
  width: 40%;
  height: 3rem;
  top: 0;
  left: 11rem;
  border-radius: 0.4rem;
}

span p {
  position: absolute;
  width: 60%;
  height: 6rem;
  bottom: 0;
  left: 11rem;
  border-radius: 0.4rem;
}

.wrapper * {
  background: linear-gradient(120deg,
      #e5e5e5 30%,
      #f2f2f2 38%,
      #f2f2f2 40%,
      #e5e5e5 48%);
  background-size: 300%;
  background-position: 100% 0;
  animation: anim 1.5s infinite;
}

.recent-section>h1 {
  color: #962168;
}

.recents-wrapper {
  background: linear-gradient(to right, #bc2e84, #df2b6d);
  color: #fff;
  border-radius: 8px;
}

@keyframes anim {
  to {
    background-position: -100% 0;
  }
}

.title {
  font-size: 30px;
}

.card-content-wrapper h4 {
  margin: 0;
}

.recents-image-wrapper {
  margin: 8px 0 0 8px;
}

@media only screen and (max-width: 1200px) {
  .organs-base {}

  .human-body-image-wrapper {
    /* display: none; */
  }

}

@media only screen and (max-width: 767px) {
  .organs-base {
    display: block;
    margin: 20px auto 20px;
    position: relative;
  }

  .human-body-image-wrapper {}

  .human-body-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    margin-top: 100px;
    z-index: -1;
    opacity: 0.5;
  }

  .human-image {
    background-color: transparent;
  }

  .title {
    font-size: 18px;
  }

  .article-list {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  .card-content-wrapper h4 {
    margin: 0;
    font-size: 14px;
  }

  .organs-list {
    display: block;
    white-space: nowrap;
  }

  .organ {
    width: 54px;
    display: inline-block;
    margin: 0 6px;
  }

  .organ img {
    width: 100%;
  }

  .card-image-wrapper>img {
    height: 90px;
  }

  .organs-section {
    min-height: 730px;
    border: none;
    padding: 0;
  }

  .custom-prev-arrow {
    width: 31px;
    left: -9px;
  }

  .custom-next-arrow {
    width: 31px;
    right: -9px;
  }

}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .human-image {
    padding: 0;
    width: 175px;
  }

  .organs-base {
    margin: 40px auto 20px;
    position: relative;
    display: block;
  }

  .title {
    font-size: 26px;
  }

  .article-list {
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 10px;
  }

  .organs-section {
    margin-top: 20px;
    min-height: 558px;
    border: none;
    padding: 0;
  }

  .organs-list {
    display: block;
    white-space: nowrap;
    text-align: center;
  }

  .organ {
    width: 70px !important;
    height: 70px;
    display: inline-block;
    margin: 0 6px;
  }

  .organ img {
    width: 100%;
    height: 100%;
  }

  .human-body-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    margin-top: 100px;
    z-index: -1;
    opacity: 0.5;
  }

  .human-image {
    background-color: transparent;
  }

  .custom-prev-arrow {
    width: 37px;
    left: -9px;
  }

  .custom-next-arrow {
    width: 37px;
    right: -9px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .organs-section {
    padding: 0 40px;
  }
}