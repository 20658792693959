.footer{
  background: #ad2064;
    text-align: center;
    color: #f1f1f1;
    padding: 20px 10px;
    font-family: sans-serif;
    font-size: 12px;
}

.footer .a {
  color: #f1f1f1;
}

.footer-links:hover {
  color: #f1f1f1;
}
