@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;600&display=swap');

* { box-sizing: border-box;}

body {
  margin: 0;
  font-family: 'Baloo Thambi 2', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #404040;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 42px;
}
.slick-dots li { background-color: #870c5b !important; width: 30px !important; height: 4px !important; border-radius: 30px;}
.slick-dots li.slick-active {  background-color: #fff !important;}
.slick-dots li button:before {
  content: none !important;
}
@media only screen and (max-width: 1440px) {
  .container-wrapper { max-width: 90%; margin: 0 auto;margin-bottom: 20px;}
}
@media only screen and (max-width: 767px) {
.container-wrapper {
  max-width: 100%;
  padding: 0 10px;
}
h1 {font-size: 20px;}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) { 
  .container-wrapper { max-width: 100%; padding: 0 10px; }
  h1 {font-size: 20px;}
}