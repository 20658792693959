.slider-wrapper {
    background-image: linear-gradient(#da296c, #7a145a);
    padding: 40px 0px;
}

.video-card-wrapper {
    margin: 0 6px;
    width: 100%;
    display: block;
}

.slick-slide:last-of-type > div > a{ margin-right: 0;}

.video-card-image-wrapper>img:first-child {
    border-radius: 18px;
    width: 100%;
}

.video-card-image-wrapper {
    position: relative;
}

.video-card-image-wrapper>img:nth-child(2) {
    position: absolute;
    width: 66px;
    top: 41%;
    left: 45%;
}

.video-card-content-wrapper {
    color: #fff;
}

.video-card-content-wrapper h4 {
    margin-bottom: 0;
}

.slider-wrapper h1 {
    color: #fff;
}

@media only screen and (max-width: 1280px) {
    .video-card-image-wrapper>img:nth-child(2) {
        position: absolute;
        width: 59px;
        top: 41%;
        left: 41%;
    }
    
}

@media only screen and (max-width: 767px) {
    .video-card-image-wrapper>img:nth-child(2) {
        position: absolute;
        width: 45px;
        top: 42%;
        left: 42%;
    }
    .slider-wrapper {
        padding: 20px 0;
    }
    .video-card-content-wrapper {
        height: 88px;
        overflow: hidden;
    }
    .video-card-content-wrapper h4 {
        margin-top: 10px;
    }
}
@media only screen and (min-width: 767px) and (max-width: 1024px) { 
    .video-card-content-wrapper h4 { margin-bottom: 0; margin-top: 10px; }
    .video-card-image-wrapper>img:nth-child(2) {
        position: absolute;
        width: 57px;
        top: 42%;
        left: 42%;
    }
}

@media only screen and (max-width: 350px) {
    .video-card-image-wrapper>img:nth-child(2) {
        position: absolute;
        width: 41px;
        top: 40%;
        left: 40%;
    }
}