.content-wrapper {
  max-width: 1440px;
  margin: 43px auto;
  text-align: left;
}

.content-wrapper>img {
  width: 100%;
}

.content-wrapper>div {
  margin: 10px 0;
  font-size: 16px;
  line-height: 30px;
}

.article-base .recent-wrapper {
  width: 1067px;
  margin: auto;
}

/* .content-wrapper .recent-wrapper {
    width: 1067px;
    margin: auto;
} */

.article-header-img {
  text-align: center;
}

.article-header-img img {
  width: 500px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#preloader {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: fadeout 7s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 7s;
  /* Firefox < 16 */
  -ms-animation: fadeout 7s;
  /* Internet Explorer */
  -o-animation: fadeout 7s;
  /* Opera < 12.1 */
  animation: fadeout 7s;
}

.preactive {
  animation: fadeout 7s linear;
}

.inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 54px;
  height: 54px;
  margin: auto
}

.page-loader {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fefefe;
  z-index: 100000
}

#preloader span.loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 10px;
  text-indent: -12345px;
  border-top: 1px solid rgba(0, 0, 0, .08);
  border-right: 1px solid rgba(0, 0, 0, .08);
  border-bottom: 1px solid rgba(0, 0, 0, .08);
  border-left: 1px solid rgba(0, 0, 0, .5);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spinner .7s infinite linear;
  -moz-animation: spinner .7s infinite linear;
  -ms-animation: spinner .7s infinite linear;
  -o-animation: spinner .7s infinite linear;
  animation: spinner .7s infinite linear;
  z-index: 100001;

}

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* @-webkit-keyframes fade{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@media only screen and (max-width: 1280px) {
  .content-wrapper {
    max-width: 100%;
    padding: 0 30px;
  }
  .mobile {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .content-wrapper {
    padding: 0 10px;
  }

  .article-header-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content-wrapper {
    max-width: 100%;
  }

  .content-wrapper>div {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .content-wrapper {
    max-width: 90%;
  }
}