.faq-base {
  margin: 40px auto;
}

.faq-wrapper {
  /* display: grid; */
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
}

.faq-wrapper>a {
  background: #fef6e6;
}

.modal {
  /* display: block; */
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #9f2373;
  border: 1px solid #9f2373;
  border-radius: 10px;
  display: block;
  margin-top: 30px;
  text-align: left;
  width: 600px;
  padding: 40px;
  min-height: auto;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  background-color: #fff;
  color: #9f2373;
  position: absolute;
  right: -10px;
  top: -16px;
  border-radius: 9999px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 30px;
  line-height: 32px;
  cursor: pointer;
}

.popup-title {
  margin-bottom: 10px;
  font-size: 30px;
}

.modal-content h1 {
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  padding: 0 0px;
  text-align: left;
}

.slide-item ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  padding: 0 20px;
  width: 85%;
  margin: 0 auto;
}

.slide-item li {
  font-weight: bold;
  margin: 15px 0;
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 5px;
  text-align: left;
  align-items: center;
  /* list-style: none; */
}

.slide-item ul li span:first-child {
  display: inline-block;
  vertical-align: middle;
  padding: 0 2px;
}

/* .quiz-btn-wrapper {
  position: fixed;
  width: 100%;
  bottom: 62px;
  left: 50%;
  transform: translateX(-50%);
} */

.quiz-btn {
  background: linear-gradient(to bottom, #e02b6d, #721359);
  padding: 10px 20px;
  border-radius: 21px;
  cursor: pointer;
  color: #fff;
  width: max-content;
  margin: 0 auto;
}

.faq-wrapper>a {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #404040;
  padding: 15px;
}

.faq-base>h1 {
  color: #962168;
}

.success {
  accent-color: green;
}

.error {
  accent-color: red;
}

.disable {
  pointer-events: none;
}

.score-page {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  min-height: 400px;
}

.options-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  counter-reset: listStyle;
}

.options-list li {
  background-color: #fff;
  border-radius: 30px;
  padding: 5px;
  counter-increment: listStyle;
}

.options-list li:before {
  content: counter(listStyle, upper-alpha);
  background: #bdbdbd;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.options-list li span.option-span {
  display: grid !important;
  grid-template-columns: auto max-content;
  align-items: center;
}

.score-text h3 {
  margin: 0;
}

.text-orange {
  color: #ffa64d;
  font-size: 34px;
}

.cta-wrapper {
  text-align: center;
}

.cta-btn {
  background-color: #eeb315;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff
}

input[type=checkbox] {
  position: relative;
  border: 2px solid #acacac;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  opacity: .5;
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background-color: #000;
  opacity: 1;
}

input[type=checkbox].error:checked {
  background-color: #e92227;
}

input[type=checkbox].success:checked {
  background-color: #269457;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.score-text {
  color: #fff;
  text-align: center;
}

input[type=checkbox].error:before {
  content: "X";
  border: 0;
  transform: none;
  color: #fff;
  font-size: 10px;
  margin-top: calc(1px / 2);
}

.iframe-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.close-div {
  color: #fff;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  margin: 20px;
}

.video-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 80%;
}

.video-iframe iframe {
  width: 90%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-grid {
  display: block;
  /* width: 100%; */
}

.video-box {
  position: relative;
  cursor: pointer;
}

.thumbnail img {
  width: 100%;
  border-radius: 20px;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  height: max-content;
}

.fixed-elements {
  display: none;
}

.quiz-btn-wrapper-desktop {
  bottom: 62px;
  left: 50%;
  position: fixed;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .faq-base>h1 {
    font-size: 20px;
  }

  .faq-base {
    margin: 20px auto 50px;
  }

  .faq-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  .faq-wrapper>a {
    height: auto;
    font-size: 16px;
  }

  .modal-content {
    width: 90%;
  }

  .slide-item ul {
    grid-template-columns: repeat(1, 1fr);
  }

  .slide-item li {
    margin: 10px 0;
  }

  .score-page {
    grid-template-columns: repeat(1, 1fr);
  }

  .video-iframe iframe {
    height: auto;
  }

  .quiz-btn {
    font-size: 12px;
  }

  .fixed-elements {
    position: fixed;
    bottom: 50px;
    /* left: 50%;
    transform: translateX(-50%); */
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
  }

  .quiz-btn-wrapper {
    margin-right: 10px;
  }
  .quiz-btn-wrapper-desktop {
    display: none;
  }

  .close-div img {
    width: 31px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .faq-wrapper>a {
    height: auto;
    font-size: 16px;
  }

  .faq-base>h1 {
    font-size: 20px;
  }

  .faq-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .fixed-elements {
    position: fixed;
    bottom: 50px;
    /* left: 50%;
    transform: translateX(-50%); */
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
  }

  .quiz-btn-wrapper {
    margin-right: 10px;
  }
  .quiz-btn-wrapper-desktop {
    display: none;
  }
}