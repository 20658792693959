.header-wrapper {
    background: url(https://gumlet.assettype.com/vikatan/2023-03/59d2d4cb-8519-42bf-a2e0-b327af991b79/Group_41056_3x.png) no-repeat;
    background-size: 100%;
    position: relative;
    border-radius: 0 0 30px 30px;
}

header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* align-items: center; */
    /* background-size: cover; */
    /* height: 637px; */
    /* width: 1167px; */
    /* object-fit: cover; */
    /* background-size: cover; */
    margin: auto;
    position: relative;
    max-width: 1440px;
    padding: 30px 20px;
    margin: 0 auto;
}

.header-text {
    margin: auto;
    font-size: 90px;
    line-height: 90px;
    color: #fff;
    font-family: '1_TAUN_Tamil014';
}

.header-text>img {
    width: 230px;
}

.icon-wrapper {
    width: 42px;
    background: #fff;
    position: fixed;
    border-radius: 8px 0 0 8px;
    right: 0;
    top: 20%;
    transform: translateY(-50%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.icon-wrapper a {
    display: block;
    padding: 5px;
}

.icon-wrapper a img {
    vertical-align: middle;
}

.mid-grid {
    margin: auto;
}

.mid-grid-image {
    width: 230px;
}

.brand-logo {
    justify-self: end;
}

.search-wrapper {
    /* display: none; */
    width: 80%;
    margin: 0 auto;
    position: absolute;
    top: calc(100% - 35px);
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid #c12d7f;
    border-radius: 30px;
    z-index: 9;
}

.search-wrapper input {
    width: 100%;
    padding: 16px;
    border-radius: 28px 28px 0 0;
    font-size: 18px;
    color: #919191;
    font-weight: bold;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

.search-wrapper input:focus {
    outline: none;
}

.search-result {
    background-color: #fff;
    margin: 0;
    padding: 0 20px 10px;
    border-radius: 0 0 30px 30px;
}

.search-result li {
    list-style: none;
    padding: 10px 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.search-wrapper.default-search-wrapper input {
    border-radius: 30px;
    border-bottom: 0;
}

.search-wrapper.default-search-wrapper .search-result {
    padding: 0;
}

.third-grid-image {
    width: 162px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}



@media only screen and (max-width: 767px) {
    header {
        display: block;
        width: 100%;
        padding: 20px;
    }

    .header-text {
        font-size: 40px;
        line-height: 22px;
        text-align: center;
        display: flex;
        justify-content: center;
        grid-gap: 10px;
        padding: 18px 5px;
    }

    .mid-grid-image {
        height: auto;
        margin: 0;
        width: 100px;
    }

    .third-grid-image {
        float: none;
        height: auto;
        margin: 0;
        position: absolute;
        bottom: 32px;
        right: 46px;
        width: 100px;
    }

    .icon-wrapper a {
        padding: 3px;
    }

    .header-wrapper {
        background-size: auto;
        padding-bottom: 20px;
    }

    .search-wrapper {
        top: calc(100% - 8px);
        border-radius: 18px;
    }

    .search-wrapper input {
        padding: 8px;
        font-size: 14px;
        border-radius: 15px 15px 0 0;
    }

    .search-result {
        border-radius: 0 0 15px 15px;
    }

    .header-text {
        font-size: 30px;
        line-height: 38px;
      }
      .header-text>img {
        width: 205px;
      }

      .icon-wrapper {
        width: 33px;
      }

    .icon-wrapper a img {
        width: 27px;
      }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .header-text {
        font-size: 40px;
        line-height: 46px;
    }
    .mid-grid-image,.third-grid-image {
        width: 180px;
    }
    .header-text>img {
        width: 257px;
      }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .mid-grid-image {
        width: 200px;
    }
    .header-text {
        font-size: 54px;
        line-height: 60px;
      }
}