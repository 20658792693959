.recent-article-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.recent-card-wrapper {
  /* display: grid;
  grid-template-rows: 247px 99px;
  margin: auto; */
}

.recent-card-image-wrapper>img {
  width: 100%;
  vertical-align: middle;
  height: 290px;
  object-fit: cover;
}

.recent-card-content-wrapper {
  background-image: linear-gradient(#da296c, #7a145a);
  color: #fff;
  text-align: center;
  padding: 10px;
  height: 100px;
  overflow: hidden;
}

.recent-card-content-wrapper h4 {
margin: 0;
}

.recent-wrapper>h1 {
  color: #962168;
}

.card {
  position: relative;
  width: 32rem;
  height: 12rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
  padding: 1rem 2rem;
}
.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.img {
  width: 10rem;
  height: 100%;
  border-radius: 50%;
}
span h1 {
  position: absolute;
  width: 40%;
  height: 3rem;
  top: 0;
  left: 11rem;
  border-radius: 0.4rem;
}
span p {
  position: absolute;
  width: 60%;
  height: 6rem;
  bottom: 0;
  left: 11rem;
  border-radius: 0.4rem;
}
.wrapper * {
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f2f2f2 38%,
    #f2f2f2 40%,
    #e5e5e5 48%
  );
  background-size: 300%;
  background-position: 100% 0;
  animation: anim 1.5s infinite;
}
@keyframes anim {
  to {
    background-position: -100% 0;
  }
}

@media only screen and (max-width: 1280px) {
  .recent-card-content-wrapper { height: 88px;}
}

@media only screen and (max-width: 767px) {
.recent-article-list {
  display: grid;
  grid-gap: 10px;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
}
.recent-card-wrapper {
  width: 280px;
}
.recent-card-content-wrapper {
height: 108px;
}
.recent-card-content-wrapper h4 {
white-space: normal;
font-size: 14px;
}
.recent-card-image-wrapper>img {
  height: 212px;
}
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .recent-article-list { grid-gap: 10px; }
  .recent-card-content-wrapper{ height: 180px;}
  .recent-card-content-wrapper h4 {
    white-space: normal;
    font-size: 14px;
  }
  .recent-article-list {
    display: grid;
    grid-gap: 10px;
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
  }
  .recent-card-wrapper {
    width: 270px;
}
.recent-card-content-wrapper {
  height: 110px;
}
}